import React from 'react'
import PlayIcon from 'icons/Play'
import Headphones from 'icons/Headphones'
import Gallery from 'icons/Gallery'
import LiveNow from 'icons/LiveNow'
import Playlist from 'icons/Playlist'

const StoryCardIndicator = ({ story, playlist }) => {
  let icon, title

  switch (story.type) {
    case 'barstool_original':
    case 'video_podcast':
      icon = <PlayIcon color='white' />
      title = 'Watch Now'
      break
    case 'podcast':
      icon = <Headphones width='20px' />
      title = 'Listen Now'
      break
    case 'live':
      icon = <LiveNow />
      title = 'Watch Live'
      break
    case 'gallery':
      icon = <Gallery width='20px' />
      title = 'View Now'
      break
    default:
      if (playlist) {
        icon = <Playlist width='20px' color='white' />
        title = story.story_count
      } else {
        return null
      }
  }

  return (
    <span tabIndex='0' className={`bs-storyCardIndicator ${playlist ? 'bs-storyCardIndicator--playlist' : ''}`}>
      {icon}
      <span>{title}</span>

      <style jsx>{`
        .bs-storyCardIndicator {
          margin: 0;
          vertical-align: baseline;
          text-transform: uppercase;
          outline: none;
          text-align: center;
          text-decoration: none;
          font-weight: 600;
          cursor: pointer;
          transition: all cubic-bezier(0.5, 0, 0.5, 1) 150ms;
          color: white;
          background: rgba(0, 0, 0, 0.55);
          border-color: rgba(0, 0, 0, 0.55);
          position: absolute;
          border: 2px solid white;
          border-radius: 50px;
          letter-spacing: 0.05rem;
          display: inline-block;
          width: 2.8125rem;
          height: 2.8125rem;
          left: 7px;
          bottom: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .bs-storyCardIndicator:hover {
          background: rgba(0, 0, 0, 0.55);
          color: white;
          border-color: rgba(0, 0, 0, 0.55);
        }
        .bs-storyCardIndicator--playlist {
          width: 85px;
        }
        .bs-storyCardIndicator > .iconPlay {
          position: relative;
          top: 1.5px;
          left: 1.5px;
          margin: 0;
          vertical-align: middle;
        }
        .bs-storyCardIndicator span {
          display: none;
          padding-left: 12px;
        }
        .bs-storyCardIndicator--playlist span {
          position: relative;
          display: inline-block;
        }
        .bs-storyCardIndicator span:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 2px;
          top: 2px;
          width: 1px;
          background-color: rgba(255, 255, 255, 0.5);
          margin: 0 0.25rem;
        }
      `}</style>
    </span>
  )
}

export default StoryCardIndicator
