/**
 * A function to take in a url and some param values for a fastly image url
 * and apply them in a way that makes sense for the site.
 *
 * @param {String} url
 * @param {Object} params
 */
export const applyFastlyParams = (url, params = {}, options = { overwriteCrop: true }) => {
  let urlObj = new URL(url)
  for (const key in params) {
    // can't crop on top of an image with a canvas
    if (key === 'crop' && !!urlObj.searchParams.get('canvas')) continue

    // if the image already has a crop param and the option has been passed to not overwrite crop, continue
    if (key === 'crop' && !!urlObj.searchParams.get('crop') && !options.overwriteCrop) continue
    urlObj.searchParams.set(key, params[key])
  }
  return urlObj.href
}
