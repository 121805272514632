import React from 'react'

export default function Headphones({ width = '28px' }) {
  return (
    <svg
      aria-labelledby='headphones-icon'
      viewBox='0 0 28 26'
      className='svgIcon navIcon iconHeadphones'
      style={{ width }}
    >
      <title id='headphones-icon'>Listen Now</title>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-12.000000, -13.000000)' fill='#FFFFFF' fillRule='nonzero'>
          <g transform='translate(1.000000, 1.000000)'>
            <g transform='translate(11.250000, 12.500000)'>
              <path d='M4.79706633,24.8800427 L6.0697551,24.8800427 C6.44448469,24.8800427 6.75035204,24.5726809 6.75035204,24.1974903 L6.75035204,14.166387 C6.75035204,13.7911964 6.44442857,13.4838347 6.0697551,13.4838347 L4.79706633,13.4838347 C4.74167347,13.4933296 4.69116327,13.5153327 4.63633163,13.5264196 L4.63633163,10.8625038 C4.63633163,5.83305096 8.72434694,1.741205 13.75,1.741205 C18.7756531,1.741205 22.8645663,5.83390379 22.8645663,10.8625038 L22.8645663,13.5267038 C22.8094541,13.5155601 22.7586633,13.4934433 22.7029898,13.4838915 L21.4302449,13.4838915 C21.0555153,13.4838915 20.74875,13.7912533 20.74875,14.1664439 L20.74875,24.1984 C20.74875,24.5736475 21.0555153,24.8809524 21.4302449,24.8809524 L22.7029898,24.8809524 C25.4254898,24.4133713 27.5,22.0427172 27.5,19.1819671 C27.5,17.0293544 26.3245714,15.1562443 24.5833163,14.1608152 C24.5833163,13.0802162 24.5833163,10.8625038 24.5833163,10.8625038 C24.5833163,4.87275864 19.7235051,0 13.75,0 C7.77733673,0 2.91758163,4.87275864 2.91758163,10.8625038 L2.91758163,14.160474 C1.17582143,15.1556757 0,17.0290702 0,19.1819671 C0,22.0427172 2.0745102,24.4125185 4.79706633,24.8800427 Z'></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
