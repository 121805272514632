import React from 'react'
import { applyFastlyParams } from 'utils/image'

import GifToVideo from 'components/GifToVideo'

const StoryThumbnail = ({ src, alt, aspectRatio = '4:3,smart', ...props }) => {
  return (
    <>
      {src.includes('.gif') ? (
        <GifToVideo src={applyFastlyParams(src, { crop: aspectRatio }, { overwriteCrop: false })} />
      ) : (
        <img
          src={applyFastlyParams(src, { crop: aspectRatio, format: 'jpg' }, { overwriteCrop: false })}
          alt={alt}
          className='thumbnail'
          {...props}
        />
      )}
      <style jsx>{`
        .thumbnail {
          display: block;
          width: 100%;
          height: auto;
        }
      `}</style>
    </>
  )
}

export default StoryThumbnail
