import React from 'react'
import THEME from 'utils/theme'

export default function PlayIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg
      viewBox='0 0 14.68 19.92'
      aria-labelledby='play-icon'
      className='svgIcon iconPlay'
      style={{ width, height, fill: color }}
    >
      <title id='play-icon'>Play Video</title>
      <polygon points='0 0 14.69 9.96 0 19.92 0 0' />
    </svg>
  )
}
